<template>
  <div>
    <b-row class="mx-0 mt-5 uello-list-file-items">
      <b-col>
        <div class="list-file-items rounded h-100">
          <div
            class="d-flex align-items-center"
            :class="formattedOperations ? 'justify-content-between px-3' : 'justify-content-center'"
          >
            <div class="d-flex justify-content-start align-items-center flex-column p-2">
              <icon icon="check" :color="`var(--${type})`" :size="50" />
              <p class="text-primary font-weight-bold">{{ $t('requestItems.allOk') }}</p>
            </div>

            <v-select
              v-if="formattedOperations"
              v-model="operationForAll"
              ref="vue-select-list-item"
              label="text"
              maxHeight="100px"
              class="mt-2 v-select-file-item rounded"
              @input="changeOpConfirm"
              :options="formattedOperations"
              :reduce="setOperationForAll"
              :placeholder="$t('form.placeholders.selectOperation')"
              :clearable="false"
              :clearSearchOnSelect="false"
              style="width: 250px"
            ></v-select>
          </div>
          <p class="text-right text-primary px-3 mb-0">
            <small
              ><b>{{ $t('pages.request.ordersCompleted', { count: successLength }) }}</b></small
            >
          </p>
          <perfect-scrollbar :style="{ height: `${containerHeight - 350}px` }" class="px-2">
            <div class="d-flex flex-column justify-content-start align-items-center p-2">
              <div
                class="file-item-desc rounded bg-light w-100 py-2 mb-2"
                v-for="(item, index) in files.success"
                :key="`file-item-${index}`"
              >
                <b-row class="mx-0">
                  <b-col cols="1"
                    ><small><b class="text-transition">#</b></small></b-col
                  >
                  <b-col cols="3"
                    ><small><b class="text-transition">Número NF-e</b></small></b-col
                  >
                  <b-col cols="3"
                    ><small><b class="text-transition">Nome</b></small></b-col
                  >
                  <b-col cols="5"
                    ><small><b class="text-transition">Endereço</b></small></b-col
                  >
                </b-row>
                <b-row class="mx-0">
                  <b-col cols="1"
                    ><small
                      ><b class="text-secondary">{{ index + 1 }}</b></small
                    ></b-col
                  >
                  <b-col cols="3"
                    ><small
                      ><b class="text-secondary">{{ item.invoice_number }}</b></small
                    ></b-col
                  >
                  <b-col cols="3"
                    ><small
                      ><b class="text-secondary">{{ item.name }}</b></small
                    ></b-col
                  >
                  <b-col cols="5"
                    ><small
                      ><b class="text-secondary">{{ addressFormatted(item) }}</b></small
                    ></b-col
                  >
                </b-row>
                <b-row class="mx-0" v-if="operations.operation.length > 1">
                  <b-col class="text-right">
                    <v-select
                      v-model="item.operation"
                      ref="vue-select-list-item"
                      label="text"
                      maxHeight="100px"
                      class="mt-2 v-select-file-item rounded"
                      :options="formattedOperations"
                      :reduce="setOperation"
                      :placeholder="$t('form.placeholders.selectOperation')"
                      :clearable="false"
                      :clearSearchOnSelect="false"
                      @input="value => setOperationItem(value, index)"
                    ></v-select>
                  </b-col>
                </b-row>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </b-col>
      <b-col>
        <div class="list-file-items rounded h-100">
          <div class="d-flex justify-content-start align-items-center flex-column p-2">
            <icon icon="information" color="var(--warning)" :size="50" />
            <p class="text-warning font-weight-bold">{{ $t('requestItems.correctionNeeded') }}</p>
          </div>
          <p class="text-right text-primary px-3 mb-0" v-if="failedLength">
            <small
              ><b>{{ $t('pages.request.ordersFailed', { count: failedLength }) }}</b></small
            >
          </p>
          <perfect-scrollbar
            :style="{ height: `${containerHeight - 350}px` }"
            class="px-2"
            v-if="failedLength"
          >
            <div class="d-flex flex-column justify-content-start align-items-center p-2">
              <div
                class="file-item-desc rounded bg-light w-100 py-2 mb-2"
                v-for="(item, index) in files.failed"
                :key="`file-item-${index}`"
              >
                <b-row class="mx-0">
                  <b-col cols="1"
                    ><small><b class="text-transition">#</b></small></b-col
                  >
                  <b-col cols="3"
                    ><small><b class="text-transition">Número NF-e</b></small></b-col
                  >
                  <b-col cols="2"
                    ><small><b class="text-transition">Nome</b></small></b-col
                  >
                  <b-col cols="3"
                    ><small><b class="text-transition">Endereço</b></small></b-col
                  >
                </b-row>
                <b-row class="mx-0">
                  <b-col cols="1"
                    ><small
                      ><b class="text-secondary">{{ index + 1 }}</b></small
                    ></b-col
                  >
                  <b-col cols="3"
                    ><small
                      ><b class="text-secondary">{{ item.invoice_number }}</b></small
                    ></b-col
                  >
                  <b-col cols="2"
                    ><small>
                      <b class="text-secondary">{{ item.name | smallerText }}</b>
                    </small>
                  </b-col>
                  <b-col cols="3"
                    ><small
                      ><b class="text-secondary">{{
                        addressFormatted(item) | smallerText
                      }}</b></small
                    ></b-col
                  >
                  <b-col cols="3" class="px-0">
                    <uello-button
                      class="mr-3 px-3 edit-button"
                      @click="editErrors(item, index)"
                      icon="pencil"
                      size="small"
                      :text="Corrigir"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </perfect-scrollbar>

          <div class="d-flex justify-content-center align-items-center mt-3" v-if="!failedLength">
            <h4 class="text-transition">
              Sem Correções no momento :)
            </h4>
          </div>
        </div>
      </b-col>
      <lateral-edit-file
        v-if="editVisible"
        :visible="editVisible"
        :file="itemSelected"
        @close="editVisible = false"
        @changed="removeFromFailed"
      />
    </b-row>
    <div class="d-flex justify-content-end align-items-center mt-3 px-3">
      <b-button
        variant="primary"
        size="sm"
        :disabled="!sendFilesPermitted"
        class="send-button"
        v-b-modal.confirm-send-files-modal
      >
        <!-- :disabled="!!files.failed.length || loading" -->
        <!-- @click="sendItems" -->
        <icon v-if="loading" icon="loading" spin :size="22" />
        <span v-else>{{ $t('form.buttons.sendButton') }}</span>
      </b-button>
    </div>
    <b-modal
      id="confirm-send-files-modal"
      header-bg-variant="warning"
      header-text-variant="dark"
      centered
      :ok-title="$t('form.buttons.sendButton')"
      :cancel-title="$t('form.buttons.cancelButton')"
      @ok="sendItems"
    >
      <template v-slot:modal-title>
        <div class="d-flex justify-content-center">
          <uello-icon icon="alert" color="" />
          <p class="ml-2 text-dark">{{ $t('requestItems.sendFileModalHeader') }}</p>
        </div>
      </template>
      <p class="text-dark" v-html="$t('requestItems.sendFileModalBody')"></p>
    </b-modal>
  </div>
</template>

<script>
import { centralWindowSize } from '@/mixins';
import { maxLengthString } from '@/utils/index';
import api from '@/services/api';
import { UelloButton, UelloIcon } from '@uello/componentello';

export default {
  name: 'list-file-items',
  mixins: [centralWindowSize],
  props: {
    items: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: () => 'primary',
    },
    operations: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    UelloButton,
    UelloIcon,
  },
  data() {
    return {
      editVisible: false,
      itemSelected: null,
      edittedItems: {},
      files: {},
      loading: false,
      formattedOperations: null,
      operationForAll: null,
    };
  },
  beforeMount() {
    const isArray = Array.isArray(this.operations.operation);

    if (!isArray) {
      this.files = {
        ...this.items,
        success: this.items.success.map(f => ({ ...f, ...this.operations })),
      };
    } else if (this.operations.operation.length === 1) {
      this.files = {
        ...this.items,
        success: this.items.success.map(f => ({
          ...f,
          cnpj: this.operations.cnpj,
          operation: this.operations.operation[0].id,
        })),
      };
    } else {
      this.files = {
        ...this.items,
        success: this.items.success.map(f => ({
          ...f,
          cnpj: this.operations.cnpj,
          operation: null,
        })),
      };
      this.formattedOperations = this.operations.operation.map(op => ({
        value: op.id,
        text: op.name,
      }));

      this.formattedOperations = this.formattedOperations.filter(op => op.name !== 'Spot');
    }
  },
  computed: {
    successLength() {
      return this.files.success && this.files.success.length;
    },
    failedLength() {
      return this.files.failed && this.files.failed.length;
    },
    sendFilesPermitted() {
      const hasNoOperation = this.files.success.find(file => !file.operation);

      return !(hasNoOperation || this.failedLength || this.loading);
    },
  },
  watch: {
    operationForAll(value) {
      if (value) {
        this.files.success = this.files.success.map(f => ({ ...f, operation: value }));
      }
    },
    /* eslint-disable no-nested-ternary */
    files(value, oldValue) {
      if (value.success && value.success.length > (oldValue.success && oldValue.success.length)) {
        this.files.success = this.files.success.map(f =>
          f.cnpj
            ? f
            : {
                ...f,
                cnpj: this.operations.cnpj,
                operation: Array.isArray(this.operations.operation)
                  ? this.operations.operation.length > 1
                    ? null
                    : this.operations.operation[0].id
                  : this.operations.operation,
              }
        );
      }
    },
  },
  methods: {
    setOperationItem(value, index) {
      const { success, failed } = this.files;

      this.files = {
        failed,
        success: success.map((file, i) => (i === index ? { ...file, operation: value } : file)),
      };
    },
    async sendItems() {
      try {
        this.loading = true;
        const items = [...this.files.success, ...this.files.failed];
        await api.post('/orders', { items });

        this.loading = false;
        this.$toast.warning(this.$t('messages.sendFilesSuccess'));
        this.$router.push('/app/history/orders');
      } catch (error) {
        this.$toast.error(this.$t('messages.sendFilesFail'));
      }
      this.loading = false;
    },
    addressFormatted(item) {
      return `${item.address}, ${item.number}`;
    },
    editErrors(item, index) {
      const { name, document, phone, email, state_inscription } = item;
      const { invoice_key, invoice_number, invoice_total, identifier, weight, q_vol } = item;
      const { address, number, complement, neighborhood, city, uf, postcode } = item;
      const {
        pickup_address,
        pickup_number,
        pickup_neighborhood,
        pickup_city,
        pickup_complement,
        pickup_postcode,
        pickup_uf,
      } = item;

      const tabs = {
        personData: { name, document, state_inscription, phone, email },
        addressData: { address, number, complement, neighborhood, city, uf, postcode },
        invoiceData: { invoice_key, invoice_number, invoice_total, identifier, weight, q_vol },
        errors: item.error,
        index,
      };

      if (pickup_address) {
        tabs.pickupAddressData = {
          pickup_address,
          pickup_number,
          pickup_neighborhood,
          pickup_city,
          pickup_complement,
          pickup_postcode,
          pickup_uf,
        };
      }

      this.itemSelected = tabs;
      this.editVisible = true;
    },
    maxLength(str, length) {
      return maxLengthString(str, length);
    },
    updateErrors(data) {
      const { file, index } = data;
      let { success, failed } = this.files;

      failed = failed.filter((_, i) => i !== Number(index));
      success = [file, ...success];
      this.files = { success, failed };
    },
    removeFromFailed(data) {
      const { file, index } = data;
      let { success, failed } = this.files;

      failed = failed.filter((_, i) => i !== Number(index));
      success = [file, ...success];
      this.files = { success, failed };
    },
    setOperation(op) {
      return op.value;
    },
    setOperationForAll(op) {
      return op.value;
    },
    changeOpConfirm(value) {
      // eslint-disable-next-line no-alert
      const change = window.confirm('Deseja que todas as operações sejam esta?');

      if (change) {
        this.operationForAll = value;
      } else {
        this.operationForAll = null;
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
.send-button {
  width: 150px;
}
.uello-list-file-items {
  height: calc(100% - 8rem);

  .v-select-file-item {
    background-color: #fff;
  }

  .vs__dropdown-menu li {
    color: var(--transition);
    font-weight: bold;

    &:hover,
    &:active,
    &.vs__dropdown-option--highlight {
      color: #fff;
      background: var(--primary);
    }
  }

  .vs__search,
  .vs__search:focus {
    color: var(--transition);
    font-weight: bold;
  }

  .edit-button {
    /* transform: translateY(-10px); */
    margin-top: -26px;
  }

  .file-item-desc {
    border: 1px solid #999;
  }

  .list-file-items {
    border: 2px solid #e5e5e5;
  }
}
</style>
